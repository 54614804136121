@font-face {
    font-family: 'Helvetica Neue';
    src: local('Helvetica Neue'), local('HelveticaNeue'),
        url('HelveticaNeue.woff2') format('woff2'),
        url('HelveticaNeue.woff') format('woff'),
        url('HelveticaNeue.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue';
    src: local('Helvetica Neue Bold Italic'), local('HelveticaNeue-BoldItalic'),
        url('HelveticaNeue-BoldItalic.woff2') format('woff2'),
        url('HelveticaNeue-BoldItalic.woff') format('woff'),
        url('HelveticaNeue-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue';
    src: local('Helvetica Neue Bold'), local('HelveticaNeue-Bold'),
        url('HelveticaNeue-Bold.woff2') format('woff2'),
        url('HelveticaNeue-Bold.woff') format('woff'),
        url('HelveticaNeue-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue';
    src: local('Helvetica Neue Condensed Black'), local('HelveticaNeue-CondensedBlack'),
        url('HelveticaNeue-CondensedBlack.woff2') format('woff2'),
        url('HelveticaNeue-CondensedBlack.woff') format('woff'),
        url('HelveticaNeue-CondensedBlack.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue';
    src: local('Helvetica Neue Condensed Bold'), local('HelveticaNeue-CondensedBold'),
        url('HelveticaNeue-CondensedBold.woff2') format('woff2'),
        url('HelveticaNeue-CondensedBold.woff') format('woff'),
        url('HelveticaNeue-CondensedBold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue';
    src: local('Helvetica Neue Light'), local('HelveticaNeue-Light'),
        url('HelveticaNeue-Light.woff2') format('woff2'),
        url('HelveticaNeue-Light.woff') format('woff'),
        url('HelveticaNeue-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue';
    src: local('Helvetica Neue Light Italic'), local('HelveticaNeue-LightItalic'),
        url('HelveticaNeue-LightItalic.woff2') format('woff2'),
        url('HelveticaNeue-LightItalic.woff') format('woff'),
        url('HelveticaNeue-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue';
    src: local('Helvetica Neue Italic'), local('HelveticaNeue-Italic'),
        url('HelveticaNeue-Italic.woff2') format('woff2'),
        url('HelveticaNeue-Italic.woff') format('woff'),
        url('HelveticaNeue-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue';
    src: local('Helvetica Neue Medium'), local('HelveticaNeue-Medium'),
        url('HelveticaNeue-Medium.woff2') format('woff2'),
        url('HelveticaNeue-Medium.woff') format('woff'),
        url('HelveticaNeue-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue';
    src: local('Helvetica Neue UltraLight'), local('HelveticaNeue-UltraLight'),
        url('HelveticaNeue-UltraLight.woff2') format('woff2'),
        url('HelveticaNeue-UltraLight.woff') format('woff'),
        url('HelveticaNeue-UltraLight.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue';
    src: local('Helvetica Neue UltraLight Italic'), local('HelveticaNeue-UltraLightItalic'),
        url('HelveticaNeue-UltraLightItalic.woff2') format('woff2'),
        url('HelveticaNeue-UltraLightItalic.woff') format('woff'),
        url('HelveticaNeue-UltraLightItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

