html, #root, body {
    height:100%;
}

body{
    background:white;
    font-family:'Helvetica Neue', Helvetica, Arial, sans-serif;
}

a{
    color:#007CC2;
}

.lightblue-bg{
 background:#F2F8FC;   
}

.bg-dark{
    background-color:#0B2343!important;
}

.fs-6{
    font-size:10pt!important;
}

.btn{
    border-radius:35px;
}

.btn-lg{
    padding:1rem 3.5rem;
    font-size:10pt;
}

.btn-primary{
    background-color:#007CC2;
}

.btn-info{
    border-color:#F0F1FA;
    color:#4F5AED;
    background-color:#F0F1FA;
}

.btn-secondary{
    background-color:#284A8C;

    &:hover,
    &:active,
    &:focus{
        background-color:#284A8C;
        opacity:0.9;
    }
}

.btn-danger{
    color:#D64267;
    background-color:#FAF0F2;
    border-color:#FAF0F2;
}

.btn-outline-primary{
    border-color:#3E6AAB;
    color:#3E6AAB;
    border:2px solid;

    &:hover,&:active{
        border-color:#3e6aab;
        background-color:#3E6AAB;
        span.icon-add-btn{
            content:url('../images/add-btn_white-icon.svg')
        }
    }
}
.btn-success{
    background-color:#48A83E;

    &.is-invalid{
        background-color:#D64267;
        border-color:#D64267;
    }
}

div.form-card{
    border-radius:4px;
    div.card-title{
        margin-bottom:0;
    }
}

main.form-signin{
    background:#f2f2f2;
    form#loginform{
        background:white;
        padding:32px;
        
    }
}

div.form-control {
    position: relative;
    border:0;
    background:transparent;
    padding:0;
    
    label{
        position: absolute;
        top: -0.7rem;
        z-index: 1;
        left:0.5rem;
        background-color: white;
        padding: 0 5px;
        font-size:10pt
    }

    input{
        border: 1px solid gray;
        border-radius: 3px;
        position: relative;
        margin: 0px;
        line-height: 2.5rem;
        font-size:14pt;
        font-weight:500;

        &[type="date"]{
            background-image: url('../images/calendar-default-icon.svg');
            background-position:590px center;
            background-repeat:no-repeat;
            
            padding-right:2rem;
        }

    }

    
    &.is-invalid{
        background-image:none;
        padding-right:0;

        input{
            padding-right:12px;
            color:red;
        }
        &[type="date"]{
            background-image: url('../images/calendar-invalid-icon.svg');
        }
    }

    select{
        padding: .9rem .75rem;
        border: 1px solid #979797;
        border-radius: 3px;
    }

}


#loginform{
    width:100%;
    max-width:352px;
}

#iniciar-sesion::before{
    position:relative;
    margin-right:0.5rem;
    top:0.5rem;
    content:url('../../assets/images/user-icon.svg');
}


.nav-link{
    color:white
}

.datatable-wrapper{
    border:1px solid #ccc;
    border-radius:10px;
    overflow:hidden;

    header,
    .rd_Pagination{
        background-color:#f7f9fd;
    }

    header{
        border-bottom:none;
        padding:1rem;
    }

    .rdt_TableHeadRow,
    .table-striped .rdt_TableBody > div:nth-child(even){
        background:#f7f9fd;
    }
    #search-filter{
        width:320px;
        padding:0 30px;
        background:url('../images/magnifier-icon.svg');
        background-repeat: no-repeat;
        background-size: 20px;
        background-position: 5px center;
        border: 1px solid gray;
        border-radius: 4px;
    }
}

.react-select__control{
     padding: 0.58rem 0.75rem; 
     border: 1px solid #c7c7c7;
     
     .react-select__value-container{
        padding:0
     }
}

.alert{
    &.alert-danger{
        border:2px solid #FF000F;
    }

    &.alert-success{
        border:2px solid #03AA00;
    }
}

span.icon-add-btn{
    content:url('../images/add-btn-icon.svg');
}
span.icon-add-white-btn{
    content:url('../images/add-btn_white-icon.svg');
}
span.icon-remove-item{
    content:url('../images/remove-item-icon.svg');
}
span.icon-logout{
    content:url('../images/logout-icon.svg');
}
span.icon-palomita{
    content:url('../images/palomita-icon.svg');
}
span.icon-tache{
    content:url('../images/tache-icon.svg');
}
.version {
  color: white;
  padding-top: 16px;
  font-size: 12px;
}

.textareaManagment {
    border: 0;
    min-width: 100%;
    width: 100%; 
    font-size: 14px;
}

.textareaManagment:focus {
    outline: none !important;
    box-shadow: 0 0 0px #719ECE;
}