.mensaje {
    padding: 15px;
    border-radius: 3px;
    color: black;
    margin-bottom: 25px;
}

.errorMessage {
    border: 1px solid #FF000F;
    background: rgba(255, 0, 15, 0.15);
    color: #FF000F;
}

.successMessage {
    background: rgba(0, 203, 32, 0.15);
    border: 1px solid #03AA00;
}

